import React, { Component, useState } from "react";
import { Link, StaticQuery, graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Logo from "../images/logo_zenops_large.svg";
import LogoWhite from "../images/logo_zenops_large_white.svg";
import LogoSmall from "../images/logo_zenops_small.svg";
import Moderniser from "../images/moderniser.svg";
import Securiser from "../images/secure.svg";
import Simplifier from "../images/simplify.svg";
import Suse from "../images/svg/suse_medal_white.svg";
import Salt from "../images/svg/salt_medal_white.svg";
import Rancher from "../images/svg/rancher_medal_white.svg";
import Dropdown from "../components/molecules/Dropdown";

const ExpertiseOptions = [
  {
    title: "Moderniser",
    description: "Gagner en agilité avec une infrastructure transformée et évolutive.",
    icon: Moderniser,
    link: "/moderniser",
  },
  {
    title: "Sécuriser",
    description: "Intégrer la sécurité dans une approche by-design.",
    icon: Securiser,
    link: "/securiser",
  },
  {
    title: "Simplifier",
    description: "Environnement collaboratif, amélioration de la productivité, la mobilité de vos utilisateurs en toute sérénité.",
    icon: Simplifier,
    link: "/simplifier",
  },
];
const SolutionsOptions = [
  {
    title: "Saltstack",
    description: "Renforcer la sécurité et la conformité de vos infrastructures.",
    icon: Salt,
    link: "/zenops-saltstack",
  },
  {
    title: "SUSE",
    description: "Une infrastructure moderne : Sécurité, Patchs, Containers",
    icon: Suse,
    link: "/zenops-suse",
  },
  {
    title: "Rancher",
    description: "Experts en gestion des environnements Kubernetes avec SUSE RANCHER",
    icon: Rancher,
    link: "/suse-rancher",
  }
];
const RessourcesOptions = [
  {
    title: "Calendrier",
    description: " Découvrez nos prochains événements !",
    link: "/events",
  },
  {
    title: "Rediffusions",
    description:
      "Visionnez les replays de nos Wébinaires et Tutoriels avec chapitrage intégré.",
    link: "/replays",
  },
];

const SimpleLink = (props) => (
  <li className="nav-item active">
    <a
      className="nav-link"
      {...(props.link.primary.link && { href: props.link.primary.link.url })}>
      {props.link.primary.label.text}A
    </a>
  </li>
);

const ParseLink = (link) => {
  if (link.uid == "home") {
    return "/";
  }
  if (link.uid == "about") {
    return "/about";
  } else return `/${link.uid}`;
};

const Nav = ({ isHome, isDark }) => {
  const [menu, setMenu] = useState(false);
  const toggleTrueFalse = () => setMenu(!menu);

  const data = useStaticQuery(graphql`
    query navQuery {
      prismicLayout {
        data {
          nav {
            primary {
              label {
                text
              }
              link {
                uid
              }
            }
            items {
              sub_nav_link_label {
                text
              }
              sub_nav_link {
                uid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className={`fixed w-full ${isDark ? "bg-gray-900 text-white" : "bg-white"}`} style={{ zIndex: "99" }}>
        <div className="flex  max-w-screen-xl mx-auto justify-between items-center px-4  sm:px-6 md:justify-start md:space-x-10">
          <div>
            <Link to="/" className="md:p-0 p-4">
              <img
                className="h-4 w-auto sm:h-6"
                src={isDark ? LogoWhite : Logo}
                alt="Zenops"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={toggleTrueFalse}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />

              </svg>
            </button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
            <nav className="flex space-x-10">
              <Dropdown
                label={"Expertises"}
                options={ExpertiseOptions}
                isDark={isDark}
                className={"p-4"}
              />
              <Dropdown label={"Solutions"} options={SolutionsOptions} isDark={isDark} className={"p-4"}/>
              <Dropdown
                label={"Événements"}
                options={RessourcesOptions}
                isDark={isDark}
                className={"p-4"}
              />
              <Link
                to={"https://blog.zenops.fr/"}
                className={`text-base leading-6 font-medium   focus:outline-none transition ease-in-out duration-150 p-4 ${
                  isDark ? "text-gray-300 hover:text-white" : "text-gray-600 hover:text-gray-900"
                }`}>
               Blog
              </Link>
              <Link
                to={"/about"}
                href="#"
                className={`text-base leading-6 font-medium   focus:outline-none transition ease-in-out duration-150 p-4 ${
                  isDark ? "text-gray-300 hover:text-white" : "text-gray-600 hover:text-gray-900"
                }`}>
                À propos
              </Link>
            </nav>
            <div className="flex items-center space-x-8">
              <span className="inline-flex ">
                <a
                  href={isHome ? "#contact" : "/#contact"}
                  className="hover:-translate-y-1 inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 focus:outline-none focus:ring transform transition duration-150 ease-in-out ">
                  Contactez-nous !
                </a>
              </span>
            </div>
          </div>
        </div>

        <div
          className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden
        ${menu ? "block" : "hidden"}`} >
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <Link to="/">
                      <img
                        className="h-8 w-auto sm:h-10 "
                        src={LogoSmall}
                        alt="Logo zenops"
                      />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() => setMenu(false)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid gap-6">
                    <div className="label text-xs font-bold text-primary-900 uppercase border-b">
                      Expertises
                    </div>
                    <Link
                      to={"/moderniser"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white">
                        <img src={Moderniser} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Moderniser
                      </div>
                    </Link>
                    <Link
                      to={"/securiser"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Securiser} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Sécuriser
                      </div>
                    </Link>
                    <Link
                      to={"/simplifier"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Simplifier} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Simplifier
                      </div>
                    </Link>
                    <div className="label text-xs font-bold text-primary-900 uppercase border-b">
                      Solutions
                    </div>
                    <Link
                      to={"/zenops-saltstack"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Salt} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        SaltStack
                      </div>
                    </Link>
                    <Link
                      to={"/zenops-suse"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Suse} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Suse
                      </div>
                    </Link>
                    <Link
                      to={"/suse-rancher"}
                      className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-100">
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white">
                        <img src={Rancher} />
                      </div>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Rancher
                      </div>
                    </Link>
                  </nav>
                </div>
              </div>

              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    to="/about"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    À propos
                  </Link>
                  <Link
                    to="https://blog.zenops.fr/"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    Blog
                  </Link>
                  <Link
                    to="/events"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    Calendrier
                  </Link>
                  <Link
                    to="/replays"
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
                    Rediffusions
                  </Link>
                </div>
                <div className="space-y-6">
                  <span className=" w-full inline-flex ">
                    <a
                      href={isHome ? "#contact" : "/#contact"}
                      className="hover:-translate-y-1  w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 focus:outline-none focus:ring transform transition duration-150 ease-in-out">
                      Contactez-nous !
                    </a>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Nav;

// <div>
// <div
//   className={`fixed overflow-hidden ${
//     isDark ? "bg-gray-900 text-white" : "bg-white"
//   }`}>
//   <div className="hidden lg:block lg:absolute lg:inset-0 py-4">
//     <img src={Logo} alt="zenops logo" />
//   </div>
// </div>
// <div
//   className={` fixed w-full ${
//     isDark ? " bg-gray-900 text-white" : "bg-white"
//   }`}
//   style={{ zIndex: "99", top: "0" }}>
//   <nav className="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
//     <div className="flex items-center flex-1">
//       <div className="flex items-center justify-between w-full md:w-auto">
//         <Link to="/">
//           <img
//             className="h-4 w-auto sm:h-6"
//             src={isDark ? LogoWhite : Logo}
//             alt="Zenops"
//           />
//         </Link>
//         <div className="-mr-2 flex items-center md:hidden">
//           <button
//             type="button"
//             onClick={toggleTrueFalse}
//             className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
//             <svg
//               className="h-6 w-6"
//               stroke="currentColor"
//               fill="none"
//               viewBox="0 0 24 24">
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>
//           </button>
//         </div>
//       </div>
//       <div className="hidden md:ml-10 md:inline-flex items-center">
//         {data.prismicLayout.data.nav.map((l, i) => {
//           return l.primary.link.uid ? (
//             <Link
//               activeClassName={"text-primary-900"}
//               key={i}
//               to={ParseLink(l.primary.link)}
//               className={`p-4 font-medium text-gray-500  focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out ${
//                 isDark ? "hover:text-white" : "hover:text-gray-900"
//               }`}>
//               {l.primary.label[0].text}
//             </Link>
//           ) : (
//             <div
//               className={`relative p-3 rounded-t font-medium text-gray-500 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out dropdown ${
//                 isDark ? " hover:text-white" : "hover:text-gray-900"
//               }`}
//               id="navbarDropdownMenuLink"
//               role="button"
//               data-toggle="dropdown"
//               aria-haspopup="true"
//               aria-expanded="false"
//               key={i}>
//               {l.primary.label[0].text}
//               <ul
//                 className={`dropdown-menu absolute hidden left-0 flex rounded ${
//                   isDark
//                     ? " bg-white text-gray-900"
//                     : "bg-gray-900 text-gray-100 "
//                 }`}>
//                 {l.items.map((n, i) => (
//                   <li className="mb-0" key={i}>
//                     <Link
//                       className="hover:text-primary-900 py-2 px-4 whitespace-nowrap flex justify-center align-center"
//                       to={ParseLink(n.sub_nav_link)}
//                       activeClassName={"text-primary-900"}>
//                       <p>{n.sub_nav_link_label[0].text}</p>
//                     </Link>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//     <div className="hidden md:block text-right">
//       <span className="inline-flex rounded-md shadow-md transform hover:-translate-y-1 transition duration-300">
//         <span className="inline-flex ">
//           <a
//             href={isHome ? "#contact" : "/#contact"}
//             className=" inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out">
//             Contactez-nous !
//           </a>
//         </span>
//       </span>
//     </div>
//   </nav>
//   <div
//     className={`absolute menu top-0 inset-x-0 p-2 md:hidden ${
//       menu ? "block" : "hidden"
//     }`}
//     style={{ zIndex: "99" }}>
//     <div className="rounded-lg shadow-md transition transform origin-top-right">
//       <div className="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
//         <div className="px-5 pt-4 flex items-center justify-between">
//           <div>
//             <img className="h-8 w-auto " src={LogoSmall} alt="Zenops" />
//           </div>
//           <div className="-mr-2">
//             <button
//               type="button "
//               onClick={() => setMenu(false)}
//               className="thebutton inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
//               <svg
//                 className="h-6 w-6"
//                 stroke="currentColor"
//                 fill="none"
//                 viewBox="0 0 24 24">
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//         <div className="px-2 pt-2 pb-3 flex flex-col items-center">
//           <Link
//             to="/"
//             className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             Accueil
//           </Link>
//           <Link
//             to="/about"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             À propos
//           </Link>
//           <Link
//             to="/moderniser"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             Moderniser
//           </Link>
//           <Link
//             to="/securiser"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             Sécuriser
//           </Link>
//           <Link
//             to="/simplifier"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             Simplifier
//           </Link>
//           <Link
//             to="/zenops-saltstack"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             SaltStack
//           </Link>
//           <Link
//             to="/zenops-suse"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             SUSE
//           </Link>
//           <Link
//             to="/events"
//             className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
//             Évènements
//           </Link>
//         </div>
//         <div className="flex">
//           <a
//             href={isHome ? "#contact" : "/#contact"}
//             className=" text-center w-full m-4 items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out">
//             Contactez-nous !
//           </a>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
